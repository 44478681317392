import axios from '@/services/axios'
import { omit, path } from 'ramda'
import { ActionContext } from 'vuex'
import { State as GlobalState } from '../../index'

export interface JiraProject {
  id: number
  include_all_data: boolean
  name: string
  original_project: number
  project_id: string
}

export interface JiraUser {
  account_type: string
  active: null | boolean
  avatar_url: string
  company: number
  id: number
  original_name: null | number
  time_zone: string
  url: string
  user_id: string
  username: string
}

export interface JiraStatus {
  id: string
  description: string
  avatar_url: string
  name: string
  original_status: null | number
  company: number
  category: number
}

export interface State {
  projects: JiraProject[]
  statuses: JiraStatus[]
  isJiraConnected: boolean
}

type Context = ActionContext<State, GlobalState>

const state = (): State => ({
  projects: [],
  statuses: [],
  isJiraConnected: false,
})

const mutations = {
  setProjects(state: State, projects: JiraProject[]): void {
    state.projects = projects
  },
  setStatuses(state: State, statuses: JiraStatus[]): void {
    state.statuses = statuses
  },
  setJiraConnection(state: State, connected: boolean): void {
    state.isJiraConnected = connected
  },
}

const actions = {
  checkJiraConnection({ commit, rootGetters }: Context): Promise<void> {
    return axios
      .get(
        `api/companies/${rootGetters['company/selectedCompanyId']}/jira/atlassian-connect/check-connection/`
      )
      .then((data) => commit('setJiraConnection', data.data.connected))
  },
  resetJiraConnection({ commit }: Context): void {
    commit('setJiraConnection', null)
  },
  getProjects({ commit, rootGetters }: Context): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/jira/projects/`
      )
      .then((data) => commit('setProjects', data.data))
  },
  editProject(
    { rootGetters }: Context,
    project: { original_project?: number; id: number }
  ): Promise<void> {
    return axios
      .patch(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/jira/projects/${project.id}/`,
        omit(['id'], project)
      )
      .then(path(['data']))
  },
  deleteProject({ dispatch, rootGetters }: Context, id: number): Promise<void> {
    return axios
      .delete(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/jira/projects/${id}/`
      )
      .then(path(['data']))
      .then(() => dispatch('getProjects'))
  },
  getJiraConnectedProjects({ rootGetters }: Context): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/jira/add_projects/`
      )
      .then(path(['data']))
  },
  addProjects(
    { dispatch, rootGetters }: Context,
    projects: JiraProject[]
  ): Promise<void> {
    return axios
      .post(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/jira/add_projects/`,
        projects
      )
      .then(path(['data']))
      .then(() => dispatch('getProjects'))
  },
  getFirstPageOfUsers(
    { rootGetters }: Context,
    ordering: null | string
  ): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/jira/users/?limit=50&ordering=${ordering}&unmapped=false`
      )
      .then(path(['data']))
  },
  getNextPageOfUsers(_: Context, url: string): Promise<void> {
    return axios.get(`${url}`).then(path(['data']))
  },
  editUser(
    { rootGetters }: Context,
    user: { original_name: number; id: number }
  ): Promise<void> {
    return axios
      .patch(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/jira/users/${user.id}/`,
        omit(['id'], user)
      )
      .then(path(['data']))
  },
  getStatuses({ commit, rootGetters }: Context): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/jira/statuses/`
      )
      .then((data) => commit('setStatuses', data.data))
  },
  editStatus(
    { rootGetters }: Context,
    status: { original_status?: number; id: number }
  ): Promise<void> {
    return axios
      .patch(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/jira/statuses/${status.id}/`,
        omit(['id'], status)
      )
      .then(path(['data']))
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
