import axios from '@/services/axios'
import { omit, path } from 'ramda'
import { ActionContext } from 'vuex'
import { State as GlobalState } from '../../index'
import { LIMIT_OF_COMMITS } from '@/constants/constants'

export type GitHosting = 'Gitlab' | 'Bitbucket' | 'Github'

export type GitProjectStatus =
  | 'DELETION_IN_PROGRESS'
  | 'SYNCING_IN_PROGRESS'
  | 'ERROR_SYNC'
  | 'CONNECTION_ERROR'
  | 'ARCHIVED'
  | 'SYNCED'

export interface GitConfigUser {
  id: number
  name?: string
  email?: string
  original_name?: number
  company?: number
}

export interface GitProject {
  id?: number | null
  original_project?: number | null
  project_id?: number
  git_http_url?: string
  name?: string
  include_all_data?: boolean
  commit_message_exclusion_rule: null | string
  file_path_exclusion_rule: string | null
  web_url: string
  removed_irrelevant: boolean
  last_push: null | string
  avatar_url: null | string
  last_synced_at: string
  status: GitProjectStatus
  unique_id: string
  repository_id: number
  uuid: string
  full_name: string
  git_hosting: GitHosting
  added_to_analysis: boolean
  gitlab_token: number
  github_token: number
  workspace: number
}

export interface GitCommit {
  author: number | null
  committed_date: string
  created_at: string
  excluded: boolean
  id: string
  message: string
  title: string
  web_url: string
  original_name_name: string
  author_name: string
  deletions: number
  duplicated_lines: number
  ignored_lines: number
  insertions: number
  original_deletions: number
  original_insertions: number
  original_name: number
  original_project: number
  original_project_name: string
  project: number
  project_name: string
}

export interface CommitsFilter {
  projects: null | number[]
  users: null | number[]
  since: null | string
  until: null | string
  insertions_gte: null | string
  insertions_lte: null | string
  deletions_gte: null | string
  deletions_lte: null | string
  duplicated_lines_gte: null | string
  duplicated_lines_lte: null | string
  excluded?: string | null
}

export interface GlobalExclusionRules {
  commit_message_exclusion_rule: string | null
  file_path_exclusion_rule: string | null
  id?: number
}

export interface State {
  usersSearch: string | null
  global_exclusion_rules: GlobalExclusionRules
}

type Context = ActionContext<State, GlobalState>

const state = (): State => ({
  usersSearch: null,
  global_exclusion_rules: {
    commit_message_exclusion_rule: null,
    file_path_exclusion_rule: null,
  },
})

const mutations = {
  setUsersSearchText(state: State, search: string | null): void {
    state.usersSearch = search
  },
  setGlobalExclusionRules(state: State, rules: GlobalExclusionRules): void {
    state.global_exclusion_rules = rules || {
      commit_message_exclusion_rule: null,
      file_path_exclusion_rule: null,
    }
  },
}

const actions = {
  getFirstPageOfUsers(
    { rootGetters }: Context,
    params: { ordering: null | string; search: null | string }
  ): Promise<void> {
    const { ordering, search } = params || { ordering: null }
    return axios
      .get(
        `/api/companies/${
          rootGetters['company/selectedCompanyId']
        }/git/users/?limit=50&ordering=${ordering}&unmapped=false${
          search ? `&search=${search}` : ''
        }`
      )
      .then(path(['data']))
  },
  getNextPageOfUsers(_: Context, url: string): Promise<void> {
    return axios.get(`${url}`).then(path(['data']))
  },
  getFirstPageOfCommits(
    { rootGetters }: Context,
    params: { params: CommitsFilter; signal: AbortSignal }
  ): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/commits/?limit=${LIMIT_OF_COMMITS}`,
        params
      )
      .then(path(['data']))
  },
  getNextPageOfCommits(_: Context, url: string): Promise<void> {
    return axios.get(`${url}`).then(path(['data']))
  },
  editCommit(
    { rootGetters }: Context,
    commit: { excluded: boolean; id: number }
  ): Promise<void> {
    return axios
      .patch(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/commits/${commit.id}/`,
        omit(['id'], commit)
      )
      .then(path(['data']))
  },
  editUser(
    { rootGetters }: Context,
    user: { user: number; id: number }
  ): Promise<void> {
    return axios
      .patch(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/users/${user.id}/`,
        omit(['id'], user)
      )
      .then(path(['data']))
  },
  getGlobalExclusionRules({ commit, rootGetters }: Context): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/commit-exclusion-rules/`
      )
      .then((data) => commit('setGlobalExclusionRules', data.data))
  },
  addGlobalExclusionRules(
    { rootGetters }: Context,
    rules: GlobalExclusionRules
  ): Promise<void> {
    return axios
      .post(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/commit-exclusion-rules/`,
        rules
      )
      .then(path(['data']))
  },
  editGlobalExclusionRules(
    { rootGetters }: Context,
    rules: GlobalExclusionRules
  ): Promise<void> {
    return axios
      .put(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/commit-exclusion-rules/`,
        rules
      )
      .then(path(['data']))
  },
  deleteGlobalExclusionRules({ commit, rootGetters }: Context): Promise<void> {
    return axios
      .delete(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/commit-exclusion-rules/`
      )
      .then(() => commit('setGlobalExclusionRules', null))
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
