<template>
  <div class="flex text-base flex-col items-start justify-start">
    <div v-if="withTitle" class="title">Connect Github…</div>
    <div class="subtitle">
      To connect Github you need to allow repository access. When you click on
      ‘Connect’ button, the app will redirect you to Github.
    </div>
    <Button @click="getGithubInstallationUrl">Connect</Button>
  </div>
</template>

<script setup lang="ts">
import Button from '@/components/common/buttons/Button.vue'
import { useStore } from '@/store'
import { useToast } from 'primevue/usetoast'
import { defineProps } from 'vue'
import { showToastError } from '@/utils/utils'
import { useRoute } from 'vue-router'

const props = defineProps<{ withTitle?: boolean; projectId?: number }>()
const store = useStore()
const toast = useToast()
const route = useRoute()

function getGithubInstallationUrl() {
  const params = {
    install_requested_from: route.fullPath.includes('company-creation')
      ? 'COMPANY_CREATION'
      : 'ADMINISTRATION',
    install_requested_from_project_mapping: props.projectId || null,
  }
  store
    .dispatch('github/getInstallationUrl', params)
    .then((data) => {
      const urlFromBE = data.install_url
      window.open(urlFromBE, '_self')
    })
    .catch((e) => {
      showToastError(toast, e)
    })
}
</script>

<style scoped>
.title {
  @apply text-lg font-semi-bold mb-5;
}

.subtitle {
  @apply text-left mb-5;
}
</style>
