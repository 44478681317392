<template>
  <div class="w-full h-full flex">
    <CompanyCreationSidebar :company="formData"></CompanyCreationSidebar>
    <div class="h-full base-container">
      <Toast />
      <main class="h-full">
        <div
          v-if="loading"
          class="h-full w-full flex justify-center items-center"
        >
          <Loader></Loader>
        </div>
        <div v-else-if="step === 1">
          <div class="text-base py-4">Step 1 of 2</div>
          <div class="title">What is the name of your company?</div>
          <div class="text-lg mb-3">Company name</div>
          <BaseInputText
            v-model:value="formData.name"
            :errors="v$.name.$errors"
            class="w-1/2 p-inputtext-lg mb-8"
          ></BaseInputText>
          <div class="flex gap-3">
            <Button class="next" size="large" @click="onCompanyCreate"
              >Next</Button
            >
            <Button size="large" class="p-button-text" @click="router.go(-1)"
              >Go Back</Button
            >
          </div>
        </div>
        <div v-else>
          <div class="text-base py-4">Step 2 of 2</div>
          <ConnectionFlow></ConnectionFlow>
        </div>
      </main>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import Button from '@/components/common/buttons/Button.vue'
import { useStore } from '@/store'
import { useToast } from 'primevue/usetoast'
import { showToastError } from '@/utils/utils'
import { useRoute, useRouter } from 'vue-router'
import Toast from 'primevue/toast/Toast.vue'
import ConnectionFlow from '@/components/companies/creation-flow/ConnectionFlow.vue'
import CompanyCreationSidebar from '@/components/companies/creation-flow/CompanyCreationSidebar.vue'
import BaseInputText from '@/components/common/base/BaseInputText.vue'
import Loader from '@/components/common/loader/Loader.vue'

const store = useStore()
const toast = useToast()
const router = useRouter()
const route = useRoute()
const loading = ref(false)

const initialForm = () => ({
  name: '',
})

const formData = ref(initialForm())

const formRules = {
  name: { required, $lazy: true },
}

const v$ = useVuelidate(formRules, formData)
const step = ref(1)

const onCompanyCreate = async () => {
  v$.value.$touch()
  if (v$.value.$invalid) {
    return
  }
  try {
    const company = await store.dispatch('company/addCompany', formData.value)
    await store.dispatch('company/saveSelectedCompanyInLocalStorage', company)
    step.value = 2
  } catch (e) {
    showToastError(toast, e)
  }
}

onMounted(async () => {
  if (route.query.githubIntegration) {
    loading.value = true
    await store.dispatch('github/checkGithubConnection').catch((e) => {
      showToastError(toast, e)
    })
    step.value = 2
    formData.value = { name: store.state.company.selectedCompany?.name || '' }
    loading.value = false
  } else {
    await store.dispatch('filters/savePortfolioFilters', {})
    await store.dispatch('filters/saveProjectFilters', {})
    await store.dispatch('filters/saveTeamFilters', {})
    await store.dispatch('jira/resetJiraConnection')
    await store.dispatch('bitbucket/resetBitbucketConnection')
    await store.dispatch('github/resetGithubConnection')
  }
})
</script>

<style lang="scss" scoped>
.base-container {
  max-height: 100vh;
  flex-grow: 1 !important;
  overflow: auto;
  background: white;
  padding: 45px 32px 32px;
  @apply text-left;
}

.title {
  @apply text-2xl font-bold mb-8;
}
</style>
