import { createStore, useStore as baseUseStore, Store } from 'vuex'
import sidebar, { State as SidebarState } from '@/store/modules/sidebar'
import filters, { State as FiltersState } from '@/store/modules/filters'
import user, { State as UserState } from '@/store/modules/user'
import billableTime, {
  State as BillableTimeState,
} from '@/store/modules/charts/billable-time'
import loc, { State as LocState } from '@/store/modules/charts/loc'
import workload, {
  State as WorkloadState,
} from '@/store/modules/charts/workload'
import userActivity, {
  DataState as UserActivityState,
} from '@/store/modules/charts/user-activity'
import activities, {
  ActivitiesData as ActivitiesState,
} from '@/store/modules/charts/activities'
import estimationCoverage, {
  State as EstimationCoverageState,
} from '@/store/modules/charts/estimation-coverage'
import estimationAccuracy, {
  State as EstimationAccuracyState,
} from '@/store/modules/charts/estimation-accuracy'
import reopenedTickets, {
  State as ReopenedTicketsState,
} from '@/store/modules/charts/reopened-tickets'
import projectAllocation, {
  State as ProjectAllocationState,
} from '@/store/modules/charts/project-allocation'
import velocityFactor, {
  State as VelocityFactorState,
} from '@/store/modules/charts/velocity-factor'
import admin, { State as AdminState } from '@/store/modules/admin/admin'
import gitlab, { State as GitlabState } from '@/store/modules/admin/gitlab'
import github, { State as GithubState } from '@/store/modules/admin/github'
import bitbucket, {
  State as BitbucketState,
} from '@/store/modules/admin/bitbucket'
import tempo, { State as TempoState } from '@/store/modules/admin/tempo'
import jira, { State as JiraState } from '@/store/modules/admin/jira'
import slack, { State as SlackState } from '@/store/modules/admin/slack'
import git, { State as GitState } from '@/store/modules/admin/git'
import burndown, {
  BurndownData as BurndownState,
} from '@/store/modules/charts/burndown'
import company, { State as CompanyState } from '@/store/modules/company'
import jiraTickets, {
  Page as JiraTicketsState,
} from '@/store/modules/charts/jira-tickets'
import scopeVsDelivered, {
  Data as ScopeVsDeliveredState,
} from '@/store/modules/charts/scope-vs-delivered'
import budgetPlannedVsUsed, {
  BudgetPlannedVsUsed as BudgetPlannedVsUsedState,
} from '@/store/modules/charts/budget-planned-vs-used'
import stuckTickets, {
  Page as StuckTicketsState,
} from '@/store/modules/charts/stuck-tickets'
import milestones, {
  MilestonesData as MilestonesState,
} from '@/store/modules/charts/milestones'
import bugMetrics, {
  BugMetricsData as BugMetricsState,
} from '@/store/modules/charts/bug-metrics'
import commentsRates, {
  CommentsRatesData as CommentsRatesState,
} from '@/store/modules/charts/comments-rates'
import globalLoading, {
  GlobalLoadingState,
} from '@/store/modules/global-loading'

export const key = Symbol('store')

const debug = process.env.NODE_ENV !== 'production'

export interface State {
  sidebar: SidebarState
  filters: FiltersState
  user: UserState
  billableTime: BillableTimeState
  loc: LocState
  milestones: MilestonesState
  workload: WorkloadState
  activities: ActivitiesState
  userActivity: UserActivityState
  estimationCoverage: EstimationCoverageState
  estimationAccuracy: EstimationAccuracyState
  reopenedTickets: ReopenedTicketsState
  projectAllocation: ProjectAllocationState
  velocityFactor: VelocityFactorState
  admin: AdminState
  gitlab: GitlabState
  github: GithubState
  bitbucket: BitbucketState
  tempo: TempoState
  jira: JiraState
  slack: SlackState
  git: GitState
  company: CompanyState
  jiraTickets: JiraTicketsState
  burndown: BurndownState
  scopeVsDelivered: ScopeVsDeliveredState
  budgetPlannedVsUsed: BudgetPlannedVsUsedState
  stuckTickets: StuckTicketsState
  bugMetrics: BugMetricsState
  commentsRates: CommentsRatesState
  globalLoading: GlobalLoadingState
}

export const store = createStore({
  modules: {
    sidebar,
    filters,
    user,
    billableTime,
    loc,
    milestones,
    workload,
    activities,
    userActivity,
    estimationCoverage,
    estimationAccuracy,
    reopenedTickets,
    projectAllocation,
    velocityFactor,
    admin,
    gitlab,
    github,
    bitbucket,
    tempo,
    jira,
    slack,
    git,
    company,
    jiraTickets,
    burndown,
    scopeVsDelivered,
    budgetPlannedVsUsed,
    stuckTickets,
    bugMetrics,
    commentsRates,
    globalLoading,
  },
  strict: debug,
  plugins: [],
})

// define your own `useStore` composition function
export function useStore(): Store<State> {
  return baseUseStore(key)
}
