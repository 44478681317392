import axios from '@/services/axios'
import { addOverFillerData } from '@/utils/chart-utils'
import { Filters } from '@/store/modules/filters'
import { ActionContext } from 'vuex'
import { State as GlobalState } from '../../index'

export interface WorkloadPerUser {
  user: { name: string; id: number }
  workload_percent: number
  required_time_seconds: number
  time_spent_seconds: number
  filler?: number
  over_filler?: number
}

export interface WorkloadResponse {
  avg_data: {
    avg_percent: number
    all_avg_percent: number
  }
  data: WorkloadPerUser[]
}

export interface State {
  data: WorkloadResponse
}

type Context = ActionContext<State, GlobalState>

const actions = {
  getData({ rootGetters }: Context, params: Filters): Promise<State> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/charts/workload/`,
        { params }
      )
      .then(({ data }) => addOverFillerData(data, 'workload_percent'))
  },
  getLinkToOpenWorkloadReport(
    { rootGetters }: Context,
    params: { id: number; filters: Filters }
  ): Promise<{ link: string }> {
    const { since, until, projects } = params.filters
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/charts/workload/report/`,
        {
          params: {
            since,
            until,
            projects,
            original_name: params.id ? `${params.id}` : null,
          },
        }
      )
      .then(({ data }) => data)
  },
  getDrilldownData(
    { rootGetters }: Context,
    params: { id: number; filters: Filters }
  ): Promise<WorkloadResponse> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/charts/workload/drilldown/`,
        { params }
      )
      .then(({ data }) => data)
  },
}

export default {
  namespaced: true,
  actions,
}
