import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Login from '../views/Login.vue'
import PortfolioDashboard from '../views/PortfolioDashboard.vue'
import ProjectDashboard from '../views/ProjectDashboard.vue'
import TeamDashboard from '../views/TeamDashboard.vue'
import AdminDashboard from '../views/AdminDashboard.vue'
import Companies from '../views/Companies.vue'
import CompanyCreation from '../views/CompanyCreation.vue'
import GithubIntegration from '@/views/GithubIntegration.vue'
import { authService } from '@/services/auth'
import { store } from '@/store'
import DashboardLayout from '@/layouts/DashboardLayout.vue'
import LoginLayout from '@/layouts/LoginLayout.vue'
import { calculateQueryForDashboards } from '@/services/dashboards-middleware'

const selectedCompany = window.localStorage.getItem('selectedCompany')
const selectedCompanyIdFromLocalStorage = selectedCompany
  ? JSON.parse(selectedCompany).id
  : null

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: store.getters['company/selectedCompanyId']
      ? `/company/${store.getters['company/selectedCompanyId']}/portfolio`
      : selectedCompanyIdFromLocalStorage
      ? `/company/${selectedCompanyIdFromLocalStorage}/portfolio`
      : '/companies',
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      layout: LoginLayout,
    },
  },
  {
    path: '/companies',
    name: 'companies',
    component: Companies,
    meta: {
      requiresAuth: true,
      layout: LoginLayout,
    },
  },
  {
    path: '/company-creation',
    name: 'company-creation',
    component: CompanyCreation,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/github-integration',
    name: 'github-integration',
    component: GithubIntegration,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/company/:id/portfolio',
    name: 'portfolio',
    component: PortfolioDashboard,
    meta: {
      requiresAuth: true,
      layout: DashboardLayout,
    },
  },
  {
    path: '/company/:id/team',
    name: 'team',
    component: TeamDashboard,
    meta: {
      requiresAuth: true,
      layout: DashboardLayout,
    },
  },
  {
    path: '/company/:id/projects',
    name: 'projects',
    component: ProjectDashboard,
    meta: {
      requiresAuth: true,
      layout: DashboardLayout,
    },
  },
  {
    path: '/company/:id/administration',
    name: 'administration',
    component: AdminDashboard,
    meta: {
      requiresAuth: true,
      layout: DashboardLayout,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/404.vue'),
    meta: { requiresAuth: false, layout: LoginLayout },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(async (to, from, next) => {
  if (to.name !== from.name) {
    await store.dispatch('globalLoading/startGlobalLoading')
  }
  if (to.meta.requiresAuth && !(await authService.authenticated())) {
    next('/login')
  } else {
    if (to.params.id) {
      await store.dispatch(
        'company/takeIdFromRouteAndSaveCompanyInLocalStorage',
        +to.params.id
      )
      if (
        to.name === 'projects' ||
        to.name === 'portfolio' ||
        to.name === 'team'
      ) {
        await calculateQueryForDashboards(to, next)
      } else if (to.name === 'administration') {
        next()
      }
    } else {
      next()
    }
  }
})
router.afterEach(async () => {
  await store.dispatch('globalLoading/finishGlobalLoading')
})
export default router
