<template>
  <LoaderWrapper
    v-if="loading"
    :style="{ height: tableHeight }"
  ></LoaderWrapper>
  <template v-else>
    <div class="flex justify-between items-start">
      <CommitsFiltersMenu
        v-if="!hideFilters"
        :isLOC="!!isLOC"
        :git_hosting="git_hosting"
      ></CommitsFiltersMenu>
      <div v-if="!hideSourceSelector" class="flex items-center">
        <div class="text-black text-base mr-2">Source:</div>
        <BaseSelectButton
          v-model:selection="gitSource"
          simple-array
          :options="sourceSelectorOptions"
        ></BaseSelectButton>
      </div>
    </div>
    <MyTable
      :table-data="gitCommits"
      :columns-data="columnsData"
      lazy-table
      :lazy-loading="lazyLoading"
      :scrollable="scrollableTable"
      :custom-height="tableHeight"
      @load="onLazyLoad"
      @sort="onSort"
      v-bind="$attrs"
    >
      <template #message="{ data }">
        <Link :href="data.web_url" style-class="truncate">
          {{ data.message }}
        </Link>
      </template>
      <template #insertions="{ data }">
        {{
          gitSource === 'DevFlow' ? data.insertions : data.original_insertions
        }}
      </template>
      <template #deletions="{ data }">
        {{ gitSource === 'DevFlow' ? data.deletions : data.original_deletions }}
      </template>
      <template #ignored_lines="{ data }">
        {{ gitSource === 'DevFlow' ? data.ignored_lines : '-' }}
      </template>
      <template #duplicated_lines="{ data }">
        {{ gitSource === 'DevFlow' ? data.duplicated_lines : '-' }}
      </template>
      <template #committed_date="{ data }">
        {{ calcDateFormat(data.committed_date) }}
      </template>
      <template #original_name_name="{ data }">
        <div class="truncate">
          {{
            data.original_name_name
              ? data.original_name_name
              : `Unmapped (${data.author_name})`
          }}
        </div>
      </template>
      <template #excluded="{ data }">
        <BaseSelectButton
          v-model:selection="data.excluded"
          :options="excludedOptions"
          :allow-empty="false"
          @change="onChangeExcluded($event as boolean, data.id)"
        ></BaseSelectButton>
      </template>
    </MyTable>
  </template>
</template>

<script setup lang="ts">
import LoaderWrapper from '@/components/common/loader/LoaderWrapper.vue'
import MyTable from '@/components/common/table/MyTable.vue'
import { computed, defineEmits, defineProps, onMounted, ref, watch } from 'vue'
import { calcDateFormat } from '@/utils/date-utils'
import {
  GIT_HOSTING,
  LIMIT_OF_COMMITS,
  USER_ROLES,
} from '@/constants/constants'
import { useStore } from '@/store'
import { calcSortOrderingAndField, showToastError } from '@/utils/utils'
import { useToast } from 'primevue/usetoast'
import { CommitsFilter, GitCommit, GitHosting } from '@/store/modules/admin/git'
import { debounce } from 'lodash'
import { Dictionary, equals, isEmpty, omit, reject } from 'ramda'
import CommitsFiltersMenu from '@/components/admin-dashboard/git/commits/CommitsFiltersMenu.vue'
import Link from '@/components/common/Link.vue'
import BaseSelectButton from '@/components/common/base/BaseSelectButton.vue'

const props = defineProps<{
  isLOC?: boolean
  filters: CommitsFilter
  search?: string | null
  tableHeight: string
  refreshTable?: number
  selectedSource?: string
  hideSourceSelector?: boolean
  hideFilters?: boolean
  git_hosting?: GitHosting
}>()

const emit = defineEmits<{ (e: 'change-excluded'): void }>()

const store = useStore()
const toast = useToast()
const nextPage = ref<string | null>(null)
const gitCommits = ref<GitCommit[]>([])
const scrollableTable = ref(true)
const loading = ref(false)
const lazyLoading = ref(false)
const sortField = ref<string | null>(null)
const debouncedGetRequest = debounce(getFirstPageOfCommits, 1000)
let abortController = new AbortController()

const search = computed(() => props.search)
const filters = computed(() => props.filters)
const refreshTable = computed(() => props.refreshTable)
const selectedSource = computed(() => props.selectedSource)

const gitSource = ref(props.selectedSource || 'DevFlow')
const sourceSelectorOptions = [
  'DevFlow',
  props.isLOC ? 'Git' : GIT_HOSTING[props.git_hosting],
]

watch([sortField, filters, search, refreshTable, selectedSource], () => {
  if (search.value) {
    debouncedGetRequest()
  } else {
    getFirstPageOfCommits()
  }

  if (selectedSource.value) {
    gitSource.value = selectedSource.value
  }
})

onMounted(() => {
  getFirstPageOfCommits()
})

const showExcludedColumn = computed(
  () =>
    !props.isLOC ||
    [USER_ROLES.ADMIN, USER_ROLES.OWNER].includes(
      store.getters['company/userRole']
    )
)
const excludedOptions = [
  { value: false, name: 'No' },
  { value: true, name: 'Yes' },
]

const baseColumnsData = [
  {
    header: 'Message',
    field: 'message',
    is_sortable: true,
    use_template: true,
    styles: 'flex-basis:400px; padding-right:10px',
    classes: 'sm:max-w-xxs md:max-w-xxs lg:max-w-xs xl:max-w-lg 2xl:max-w-2xl',
  },
  {
    header: 'Repository name',
    field: 'repository_name',
    is_sortable: true,
    use_template: false,
    styles: 'flex-basis:200px',
  },
  {
    header: 'Insertions',
    field: 'insertions',
    is_sortable: true,
    use_template: true,
    styles: 'flex-basis:100px',
  },
  {
    header: 'Deletions',
    field: 'deletions',
    is_sortable: true,
    use_template: true,
    styles: 'flex-basis:100px',
  },
  {
    header: 'Ignored',
    field: 'ignored_lines',
    is_sortable: true,
    use_template: true,
    styles: 'flex-basis:100px',
  },
  {
    header: 'Duplicates',
    field: 'duplicated_lines',
    is_sortable: true,
    use_template: true,
    styles: 'flex-basis:100px',
  },
  {
    header: 'Date/Time',
    field: 'committed_date',
    is_sortable: true,
    use_template: true,
    styles: 'flex-basis:150px; min-width: 117px',
  },
]

const columnsData = computed(() => {
  let columns = baseColumnsData
  if (!props.isLOC) {
    columns = columns.concat({
      header: 'Author',
      field: 'original_name_name',
      is_sortable: true,
      use_template: true,
      styles: 'flex-basis:200px; padding-right:10px;',
    })
  }
  if (showExcludedColumn.value) {
    columns = columns.concat({
      header: 'Excluded',
      field: 'excluded',
      is_sortable: true,
      use_template: true,
      styles: 'flex-grow: 0; flex-basis: 100px; min-width: 100px',
    })
  }
  return columns
})

async function onChangeExcluded(excluded: boolean, id: string) {
  try {
    await store.dispatch('git/editCommit', {
      excluded,
      id,
    })
    emit('change-excluded')
  } catch (e) {
    showToastError(toast, e)
  }
}

// TODO: find way how to test lazy load on scroll
// when scrollable property is true tbody is empty in the tests

/* istanbul ignore next */
async function onLazyLoad(event: { first: number; last: number }) {
  const { last } = event
  if (!last || lazyLoading.value) return
  try {
    if (nextPage.value && last === gitCommits.value.length) {
      lazyLoading.value = true
      const page = await store.dispatch(
        props.isLOC
          ? 'git/getNextPageOfCommits'
          : `${GIT_HOSTING[
              props.git_hosting
            ].toLowerCase()}/getNextPageOfCommits`,
        nextPage.value
      )
      nextPage.value = page.next
      gitCommits.value = gitCommits.value.concat(page.results)
    }
    lazyLoading.value = false
  } catch (e) {
    lazyLoading.value = false
    showToastError(toast, e)
  }
}

function onSort(event: Event) {
  sortField.value = calcSortOrderingAndField(event)
}

async function getFirstPageOfCommits() {
  const params = {
    ...filters.value,
    ordering: sortField.value,
    search: search.value,
  }
  const activeParams = {
    ...reject(equals(null))(params as Dictionary<any>),
  }
  if (
    (props.isLOC &&
      isEmpty(
        omit(['users', 'excluded', 'projects', 'since', 'until'], activeParams)
      )) ||
    isEmpty(activeParams)
  ) {
    loading.value = true
  } else {
    lazyLoading.value = true
  }
  abortController.abort()
  abortController = new AbortController()
  try {
    const page = await store.dispatch(
      props.isLOC
        ? 'git/getFirstPageOfCommits'
        : `${GIT_HOSTING[
            props.git_hosting
          ].toLowerCase()}/getFirstPageOfCommits`,
      {
        params: { ...activeParams },
        signal: abortController.signal,
      }
    )
    nextPage.value = page?.next
    gitCommits.value = page?.results
    loading.value = false
    lazyLoading.value = false
  } catch (e) {
    if (e.message === 'canceled') {
      if (isEmpty(activeParams)) {
        loading.value = true
      } else {
        lazyLoading.value = true
      }
    } else {
      loading.value = false
      lazyLoading.value = false
      showToastError(toast, e)
    }
  }
}
</script>
