<template>
  <Dialog
    :visible="open"
    :style="{ width: '90vw' }"
    :dismissableMask="true"
    @update:visible="$emit('close')"
    position="bottom"
    modal
  >
    <template #header>
      <div class="header-wrapper">
        <span>{{ drilldownData?.name }}</span>
        <span>•</span>
        <Button
          label="Tempo"
          link
          class="button-link"
          @click="openWorkloadReport"
        />
      </div>
    </template>
    <div class="text-xl font-semi-bold mb-4">Workload</div>
    <div class="height flex mb-8" v-if="loading">
      <LoaderWrapper></LoaderWrapper>
    </div>
    <MyTable
      v-else
      :table-data="workloadData"
      :columns-data="columnsData"
      customHeight="70vh"
      :scrollable="scrollableTable"
      class="mb-8"
    >
      <template #key="{ data }">
        <Link :href="data.absolute_url" style-class="truncate">
          {{ `${data.key}: ${data.summary}` }}
        </Link>
      </template>
      <template #time_spent_seconds="{ data }">
        {{ convertSecondsToReadableWorkTime(data.time_spent_seconds) }}
      </template>
      <template #adaptive_estimation="{ data }">
        {{
          getEstimationShortReadableString(
            data.adaptive_estimation,
            data.estimation_type
          )
        }}
      </template>
      <template #remaining_time_seconds="{ data }">
        {{ convertSecondsToReadableWorkTime(data.remaining_time_seconds) }}
      </template>
      <template #status="{ data }">
        <Chip :label="data.status" :color="chipBackground(data.status)"></Chip>
      </template>
    </MyTable>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from 'primevue/dialog/Dialog.vue'
import { computed, defineProps, ref, watchEffect } from 'vue'
import LoaderWrapper from '@/components/common/loader/LoaderWrapper.vue'
import MyTable from '@/components/common/table/MyTable.vue'
import { useStore } from '@/store'
import {
  chipBackground,
  getEstimationShortReadableString,
  showToastError,
} from '@/utils/utils'
import { useToast } from 'primevue/usetoast'
import { Dictionary, equals, reject } from 'ramda'
import { Filters } from '@/store/modules/filters'
import Link from '@/components/common/Link.vue'
import { convertSecondsToReadableWorkTime } from '../../../utils/date-utils'
import Button from '@/components/common/buttons/Button.vue'
import Chip from '@/components/common/Chip.vue'

const props = defineProps<{
  open: boolean
  drilldownData?: { id: number; name: string } | null
  filters: Filters
}>()

const workloadData = ref([])
const loading = ref(false)
const store = useStore()
const toast = useToast()
const scrollableTable = ref(true)

const columnsData = [
  {
    header: 'Ticket',
    field: 'key',
    is_sortable: true,
    use_template: true,
    styles: 'flex-grow: 2.5; padding-right: 10px',
  },
  {
    header: 'Original estimation',
    field: 'adaptive_estimation',
    is_sortable: true,
    use_template: true,
    styles: 'padding-right: 10px',
  },
  {
    header: 'Time spent',
    field: 'time_spent_seconds',
    is_sortable: true,
    use_template: true,
    styles: 'padding-right: 10px',
  },
  {
    header: 'Remaining time',
    field: 'remaining_time_seconds',
    is_sortable: true,
    use_template: true,
  },
  {
    header: 'Status',
    field: 'status',
    is_sortable: true,
    use_template: true,
    styles: 'padding-right: 10px',
  },
]

watchEffect(() => {
  if (props.drilldownData) {
    getWorkloadDetails()
  }
})

const activeFilters = computed(() =>
  reject(equals(null))(props.filters as Dictionary<null>)
)

async function getWorkloadDetails() {
  loading.value = true
  try {
    workloadData.value = await store.dispatch('workload/getDrilldownData', {
      ...activeFilters.value,
      users: props.drilldownData?.id.toString(),
    })
    loading.value = false
  } catch (e) {
    showToastError(toast, e)
    loading.value = false
  }
}

const openWorkloadReport = async () => {
  const userId = props.drilldownData?.id
  const response = await store.dispatch(
    'workload/getLinkToOpenWorkloadReport',
    {
      id: userId,
      filters: props.filters,
    }
  )
  window.open(response.url, '_blank', 'noopener,noreferrer')
}
</script>

<style scoped lang="postcss">
.header-wrapper {
  @apply flex items-center justify-start w-full text-base gap-2;
}
.height {
  height: 70vh;
}
.button-link {
  @apply text-key-500 pl-0 pb-2 !important;
}
</style>
