import { Dictionary } from 'ramda'
import {
  CalendarWeekPhase,
  CalendarWeekStatus,
  PopupType,
} from '@/store/modules/charts/milestones'
import { SCALE_TYPE } from '@/store/modules/filters'
import { Aggregate_By_Type } from '@/types/types'
import { PageType } from '@/store/modules/admin/admin'
import { GitHosting } from '@/store/modules/admin/git'

export const usedColors = {
  'danger-400': '#F18984',
  'danger-500': '#ED6F6F',
  'danger-600': '#CB515B',
  'warning-300': '#F9DD76',
  'warning-500': '#EEB61E',
  'success-200': '#A2F8BA',
  'success-400': '#4CD990',
  'success-600': '#13A577',
  'info-200': '#9DE1EE',
  'info-300': '#61C5E3',
  'info-500': '#0074B3',
  'key-300': '#97A7F2',
}

export const AGGREGATE_BY: Dictionary<Aggregate_By_Type> = {
  USERS: 'users',
  PROJECTS: 'projects',
}

export const TOAST_LIFE_TIME = 7000

export const CHART_BAR_WIDTH = 27
export const LIST_ITEM_HEIGHT = 37
export const PERCENT_OF_WINDOW_70 = 0.7 * window.innerHeight

export const USER_POSITIONS = {
  DEVELOPER: 'DEVELOPER',
  MANAGER: 'MANAGER',
  TEAM_LEAD: 'TEAM_LEAD',
  DESIGNER: 'DESIGNER',
  QA: 'QA',
}

export const USER_POSITIONS_TITLES: Dictionary<string> = {
  DEVELOPER: 'Developer',
  MANAGER: 'Manager',
  TEAM_LEAD: 'Team Lead',
  DESIGNER: 'Designer',
  QA: 'QA',
}

export const USER_ROLES = {
  MEMBER: 'MEMBER',
  TEAM_LEAD: 'TEAM_LEAD',
  ADMIN: 'ADMIN',
  OWNER: 'OWNER',
}

export const USER_ROLES_TITLES: Dictionary<string> = {
  MEMBER: 'Member',
  TEAM_LEAD: 'Team Lead',
  ADMIN: 'Admin',
  OWNER: 'Owner',
}

export const TIME_FILTER_BUTTONS = {
  PREVIOUS_WEEK: 'Previous week',
  LAST_MONTH: 'Last month',
  LAST_3_MONTHS: 'Last 3 months',
  LAST_6_MONTHS: 'Last 6 months',
  LAST_YEAR: 'Last year',
}

export const FILTERS_DATE_FORMAT = 'yyyy-MM-dd'

export const TO_DO_STATUSES = ['To do', 'Open', 'In Triage', 'in Clarification']
export const DONE_STATUSES = [
  'Done',
  'Cancelled',
  'Permanent',
  "Won't fix",
  'Ready for Release',
  'Rejected',
]

export const TOKEN_STATUSES = {
  TOKEN_INVALID: 'TOKEN_INVALID',
  TOKEN_VALID: 'TOKEN_VALID',
  DELETION_IN_PROGRESS: 'DELETION_IN_PROGRESS',
}

export const TOKEN_STATUSES_TITLES = {
  TOKEN_INVALID: 'Token invalid',
  TOKEN_VALID: 'Token valid',
  DELETION_IN_PROGRESS: 'Deletion in progress',
}

export const LIMIT_OF_COMMITS = 50

export const SIDEBAR_PAGES = [
  { icon: 'administration', title: 'Administration', value: 'administration' },
  { icon: 'portfolio', title: 'Project Portfolio', value: 'portfolio' },
  { icon: 'project', title: 'Project', value: 'projects' },
  { icon: 'users', title: 'Team', value: 'team' },
]

export const USER_ACTIVITIES = {
  COMMENTS: 'Comments',
  COMMITS: 'Commits',
  MERGE_REQUESTS: 'Merge Requests',
}

export const AGGREGATION_TYPE: Dictionary<SCALE_TYPE> = {
  DATE: 'date',
  WEEK: 'week',
  MONTH: 'month',
}

export const AGGREGATION_TYPE_TITLES = {
  DATE: 'Daily',
  WEEK: 'Weekly',
  MONTH: 'Monthly',
}

export const ESTIMATION_TYPE = {
  TIME: 'TIME',
  STORY_POINTS: 'STORY POINTS',
} as const

export type ESTIMATION_TYPE =
  (typeof ESTIMATION_TYPE)[keyof typeof ESTIMATION_TYPE]

export const ESTIMATION_TYPE_TITLES = {
  TIME: 'Time',
  STORY_POINTS: 'Story points',
}

export const MILESTONE_WEEK_STATUS: Record<string, CalendarWeekStatus> = {
  GREEN: 'green',
  YELLOW: 'yellow',
  RED: 'red',
}

export const MILESTONE_WEEK_PHASE: Record<string, CalendarWeekPhase> = {
  DEVELOPMENT: 'development',
  ON_HOLD: 'on hold',
  MAINTENANCE: 'maintenance',
}

export const MILESTONE_POPUPS: Dictionary<PopupType> = {
  MILESTONE: 'milestone',
  CALENDAR_WEEK: 'calendar_week',
}

export const DASHBOARD_PAGES: Record<string, PageType> = {
  PORTFOLIO: 'PORTFOLIO',
  TEAM: 'TEAM',
}

export const GIT_HOSTING: Record<string, GitHosting> = {
  Github: 'Github',
  Gitlab: 'Gitlab',
  Bitbucket: 'Bitbucket',
}
