import axios from '@/services/axios'
import { omit, path } from 'ramda'
import { GIT_HOSTING, LIMIT_OF_COMMITS } from '@/constants/constants'
import { ActionContext } from 'vuex'
import { State as GlobalState } from '../../index'
import { GitProject, CommitsFilter } from '@/store/modules/admin/git'

export interface BitbucketUser {
  company?: number
  id: number
  original_name?: number | null
  display_name?: string
  nickname: string
}

export interface State {
  projects: GitProject[]
  commits_filters: CommitsFilter
  search: string | null
  isBitbucketConnected: boolean
}

type Context = ActionContext<State, GlobalState>

const state = (): State => ({
  projects: [],
  commits_filters: {
    projects: null,
    users: null,
    since: null,
    until: null,
    insertions_gte: null,
    insertions_lte: null,
    deletions_gte: null,
    deletions_lte: null,
    duplicated_lines_gte: null,
    duplicated_lines_lte: null,
  },
  search: null,
  isBitbucketConnected: false,
})

const mutations = {
  setProjects(state: State, projects: GitProject[]): void {
    state.projects = projects
  },
  setBitbucketConnection(state: State, connected: boolean): void {
    state.isBitbucketConnected = connected
  },
  setCommitsFilters(state: State, filters: CommitsFilter): void {
    state.commits_filters = filters
  },
  setSearchText(state: State, search: string | null): void {
    state.search = search
  },
}

const actions = {
  getProjects({ commit, rootGetters }: Context): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/repositories/?git_hosting=${GIT_HOSTING.Bitbucket}`
      )
      .then((data) => commit('setProjects', data.data))
  },
  getProject({ rootGetters }: Context, id: number): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/repositories/${id}/?git_hosting=${GIT_HOSTING.Bitbucket}`
      )
      .then(path(['data']))
  },
  editProject(
    { dispatch, rootGetters }: Context,
    project: GitProject
  ): Promise<void> {
    return axios
      .patch(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/repositories/${project.id}/?git_hosting=${GIT_HOSTING.Bitbucket}`,
        omit(['id'], project)
      )
      .then(path(['data']))
      .then(() => dispatch('getProjects'))
  },
  deleteProject({ dispatch, rootGetters }: Context, id: number): Promise<void> {
    return axios
      .delete(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/repositories/${id}/?git_hosting=${GIT_HOSTING.Bitbucket}`
      )
      .then(path(['data']))
      .then(() => dispatch('getProjects'))
  },
  addProjects(
    { dispatch, rootGetters }: Context,
    payload: { id: number; name: string; original_project: number }
  ): Promise<GitProject[]> {
    return axios
      .post(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/bitbucket/add_repositories/`,
        payload
      )
      .then((data) => {
        dispatch('getProjects')
        return data.data
      })
  },
  getNotAddedProjects({ rootGetters }: Context): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/bitbucket/not_added_repositories/`
      )
      .then(path(['data']))
  },
  getFirstPageOfUsers(
    { rootGetters }: Context,
    params: { ordering: string | null; search: string }
  ): Promise<void> {
    return axios
      .get(
        `/api/companies/${
          rootGetters['company/selectedCompanyId']
        }/bitbucket/users/?limit=50&ordering=${params.ordering}&unmapped=false${
          params.search ? `&search=${params.search}` : ''
        }`
      )
      .then(path(['data']))
  },
  getNextPageOfUsers(_: Context, url: string): Promise<void> {
    return axios.get(`${url}`).then(path(['data']))
  },
  editUser(
    { rootGetters }: Context,
    user: { original_name: number; id: number }
  ): Promise<void> {
    return axios
      .patch(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/bitbucket/users/${user.id}/`,
        omit(['id'], user)
      )
      .then(path(['data']))
  },
  getFirstPageOfCommits(
    { rootGetters }: Context,
    params: { params: CommitsFilter; signal: AbortSignal }
  ): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/commits/?limit=${LIMIT_OF_COMMITS}&git_hosting=${GIT_HOSTING.Bitbucket}`,
        params
      )
      .then(path(['data']))
  },
  getNextPageOfCommits(_: Context, url: string): Promise<void> {
    return axios.get(`${url}`).then(path(['data']))
  },
  checkBitbucketConnection({ commit, rootGetters }: Context): Promise<void> {
    return axios
      .get(
        `api/companies/${rootGetters['company/selectedCompanyId']}/bitbucket/atlassian-connect/check-connection/`
      )
      .then((data) => commit('setBitbucketConnection', data.data.connected))
  },
  resetBitbucketConnection({ commit }: Context): void {
    commit('setBitbucketConnection', null)
  },
  editStatus(
    { rootGetters }: Context,
    status: { original_status?: number; id: number }
  ): Promise<void> {
    return axios
      .patch(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/bitbucket/statuses/${status.id}/`,
        omit(['id'], status)
      )
      .then(path(['data']))
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
