<template>
  <TableWrapper :title="tableTitle">
    <template #button>
      <div class="flex gap-2">
        <TableSearchInput
          v-model:value="search"
          placeholder="Search by project"
        ></TableSearchInput>
        <IconButton
          class="p-button-outlined mr-2"
          size="small"
          icon="pi-plus"
          icon-position="left"
          icon-size="12px"
          @click="showCreateDialog = true"
          >Add</IconButton
        >
      </div>
    </template>
    <template #default>
      <LoaderWrapper v-if="loading"></LoaderWrapper>
      <MyTable
        v-else
        :table-data="githubProjects"
        :columns-data="columnsData"
        :scrollable="scrollableTable"
        :filters="filters"
        :filter-fields="filterFields"
      >
        <template #status="{ data }">
          <ProjectStatusCell :status="data.status" />
        </template>
        <template #last_synced_at="{ data }">
          {{ calcDateFormat(data.last_synced_at) }}
        </template>
        <template #original_project="{ data }">
          {{ findOriginalProjectName(data.original_project) }}
        </template>
        <template #editable="{ data }">
          <IconButton
            size="small"
            class="p-button-text invisible group-hover:visible"
            @click="selectedProjectId = data.id"
            ><i class="pi pi-pencil" style="font-size: 12px"></i
          ></IconButton>
        </template>
      </MyTable>
      <AddGithubProjectsDialog
        v-model:open="showCreateDialog"
      ></AddGithubProjectsDialog>
      <EditGithubProjectDialog
        v-model:id="selectedProjectId"
      ></EditGithubProjectDialog>
    </template>
  </TableWrapper>
</template>

<script setup lang="ts">
import TableWrapper from '@/components/common/table/TableWrapper.vue'
import IconButton from '@/components/common/buttons/IconButton.vue'
import { useStore } from '@/store'
import { computed, onMounted, ref } from 'vue'
import MyTable from '@/components/common/table/MyTable.vue'
import { useToast } from 'primevue/usetoast'
import { showToastError } from '@/utils/utils'
import { calcDateFormat } from '@/utils/date-utils'
import { GitProject } from '@/store/modules/admin/git'
import LoaderWrapper from '@/components/common/loader/LoaderWrapper.vue'
import TableSearchInput from '@/components/common/table/TableSearchInput.vue'
import useTableSearch from '@/utils/hooks/useTableSearch'
import ProjectStatusCell from '@/components/common/table/ProjectStatusCell.vue'
import AddGithubProjectsDialog from '@/components/admin-dashboard/github/projects/AddGithubProjectsDialog.vue'
import EditGithubProjectDialog from '@/components/admin-dashboard/github/projects/EditGithubProjectDialog.vue'

const store = useStore()
const toast = useToast()
const showCreateDialog = ref(false)
const selectedProjectId = ref(false)
const loading = ref(false)
const scrollableTable = ref(true)
const filterFields = ['name', 'original_project']
const { search, filters } = useTableSearch()

onMounted(() => {
  loading.value = true
  store
    .dispatch('github/getProjects')
    .catch((e) => {
      showToastError(toast, e)
    })
    .finally(() => (loading.value = false))
})

const githubProjects = computed(() => store.state.github.projects)
const originalProjects = computed(() => store.state.admin.original_projects)
const tableTitle = computed(
  () => `Repositories • ${githubProjects.value.length}`
)
const columnsData = [
  {
    header: 'Repository name',
    field: 'name',
    is_sortable: true,
    use_template: false,
    styles: 'flex-basis: 250px; padding-right: 10px',
  },
  {
    header: 'Status',
    field: 'status',
    is_sortable: true,
    use_template: true,
    styles: 'flex-basis: 70px; padding-right: 10px',
  },
  {
    header: 'Last synced',
    field: 'last_synced_at',
    use_template: true,
    is_sortable: true,
    styles: 'flex-basis:100px; min-width: 120px; padding-right: 10px',
  },
  {
    header: 'Original project',
    field: 'original_project',
    is_sortable: true,
    use_template: true,
    styles: 'flex-basis:250px; padding-right: 10px',
  },
  {
    header: '',
    field: 'editable',
    is_sortable: false,
    use_template: true,
    styles: 'flex-grow:0; flex-basis:30px',
  },
]

const findOriginalProjectName = (original_project_id: number) => {
  return originalProjects.value.find(
    ({ id }: GitProject) => id === original_project_id
  )?.name
}
</script>
