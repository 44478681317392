import axios from '@/services/axios'
import { omit, path } from 'ramda'
import { GIT_HOSTING, LIMIT_OF_COMMITS } from '@/constants/constants'
import { ActionContext } from 'vuex'
import { State as GlobalState } from '../../index'
import { CommitsFilter, GitProject } from '@/store/modules/admin/git'

export interface GitlabToken {
  name?: string
  id?: number
  token?: string
  base_url?: string
  company?: number
  status?: string
}

export interface GitlabUser {
  avatar_url?: string
  company?: number
  email?: string | null
  id: number
  name?: string
  original_name?: number | null
  user_id?: number
  username?: string
}

export interface State {
  tokens: GitlabToken[]
  projects: GitProject[]
  commits_filters: CommitsFilter
  search: string | null
}

type Context = ActionContext<State, GlobalState>

const state = (): State => ({
  tokens: [],
  projects: [],
  commits_filters: {
    projects: null,
    users: null,
    since: null,
    until: null,
    insertions_gte: null,
    insertions_lte: null,
    deletions_gte: null,
    deletions_lte: null,
    duplicated_lines_gte: null,
    duplicated_lines_lte: null,
  },
  search: null,
})

const mutations = {
  setTokens(state: State, tokens: GitlabToken[]): void {
    state.tokens = tokens
  },
  setProjects(state: State, projects: GitProject[]): void {
    state.projects = projects
  },
  setCommitsFilters(state: State, filters: CommitsFilter): void {
    state.commits_filters = filters
  },
  setSearchText(state: State, search: string | null): void {
    state.search = search
  },
}

const actions = {
  getTokens({ commit, rootGetters }: Context): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/gitlab/tokens/`
      )
      .then((data) => commit('setTokens', data.data))
  },
  addToken(
    { dispatch, rootGetters }: Context,
    token: GitlabToken
  ): Promise<void> {
    return axios
      .post(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/gitlab/tokens/`,
        {
          ...token,
        }
      )
      .then(path(['data']))
      .then(() => dispatch('getTokens'))
  },
  getToken({ rootGetters }: Context, id: number): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/gitlab/tokens/${id}/`
      )
      .then(path(['data']))
  },
  editToken(
    { dispatch, rootGetters }: Context,
    token: GitlabToken
  ): Promise<void> {
    return axios
      .patch(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/gitlab/tokens/${token.id}/`,
        omit(['id'], token)
      )
      .then(path(['data']))
      .then(() => dispatch('getTokens'))
  },
  deleteToken(
    { dispatch, rootGetters }: Context,
    payload: { id: number; verificationCode: string }
  ): Promise<void> {
    return axios
      .delete(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/gitlab/tokens/${payload.id}/`,
        {
          data: {
            verification_code: payload.verificationCode,
          },
        }
      )
      .then(path(['data']))
      .then(() => dispatch('getToken'))
  },
  getProjects({ commit, rootGetters }: Context): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/repositories/?git_hosting=${GIT_HOSTING.Gitlab}`
      )
      .then((data) => commit('setProjects', data.data))
  },
  getProject({ rootGetters }: Context, id: number): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/repositories/${id}/?git_hosting=${GIT_HOSTING.Gitlab}`
      )
      .then(path(['data']))
  },
  editProject(
    { dispatch, rootGetters }: Context,
    project: GitProject
  ): Promise<void> {
    return axios
      .patch(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/repositories/${project.id}/?git_hosting=${GIT_HOSTING.Gitlab}`,
        omit(['id'], project)
      )
      .then(path(['data']))
      .then(() => dispatch('getProjects'))
  },
  deleteProject({ dispatch, rootGetters }: Context, id: number): Promise<void> {
    return axios
      .delete(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/repositories/${id}/?git_hosting=${GIT_HOSTING.Gitlab}`
      )
      .then(path(['data']))
      .then(() => dispatch('getProjects'))
  },
  getProjectsByToken({ rootGetters }: Context, id: number): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/gitlab/tokens/${id}/add_repositories/`
      )
      .then(path(['data']))
  },
  addProjects(
    { dispatch, rootGetters }: Context,
    payload: { token: string; projects: GitProject[] }
  ): Promise<GitProject[]> {
    return axios
      .post(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/gitlab/tokens/${payload.token}/add_repositories/`,
        payload.projects
      )
      .then((data) => {
        dispatch('getProjects')
        return data.data
      })
  },
  getFirstPageOfUsers(
    { rootGetters }: Context,
    params: { ordering: string | null; search: string }
  ): Promise<void> {
    return axios
      .get(
        `/api/companies/${
          rootGetters['company/selectedCompanyId']
        }/gitlab/users/?limit=50&ordering=${params.ordering}&unmapped=false${
          params.search ? `&search=${params.search}` : ''
        }`
      )
      .then(path(['data']))
  },
  getNextPageOfUsers(_: Context, url: string): Promise<void> {
    return axios.get(`${url}`).then(path(['data']))
  },
  editUser(
    { rootGetters }: Context,
    user: { original_name: number; id: number }
  ): Promise<void> {
    return axios
      .patch(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/gitlab/users/${user.id}/`,
        omit(['id'], user)
      )
      .then(path(['data']))
  },
  getFirstPageOfCommits(
    { rootGetters }: Context,
    params: { params: CommitsFilter; signal: AbortSignal }
  ): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/commits/?limit=${LIMIT_OF_COMMITS}&git_hosting=${GIT_HOSTING.Gitlab}`,
        params
      )
      .then(path(['data']))
  },
  getNextPageOfCommits(_: Context, url: string): Promise<void> {
    return axios.get(`${url}`).then(path(['data']))
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
