<template>
  <div class="header-wrapper">
    <Button @click="onApplyClick">Apply</Button>
  </div>
  <div class="content-wrapper">
    <div class="font-semi-bold text-lg mb-3">{{ title }}</div>
    <div class="title">Commit Message Rule</div>
    <BaseTextarea
      v-model:value="formData.commit_message_exclusion_rule"
      cols="40"
      :errors="v$.commit_message_exclusion_rule.$errors"
    ></BaseTextarea>
    <div class="title mt-3">File Path Rule</div>
    <BaseTextarea
      v-model:value="formData.file_path_exclusion_rule"
      cols="40"
      :errors="v$.file_path_exclusion_rule.$errors"
    ></BaseTextarea>
    <div>Use RegEx (Regular Expressions).</div>
    <Link
      href="https://docs.python.org/3/library/re.html"
      style-class="font-semi-bold"
    >
      Follow the link to find examples RegEx.
    </Link>
  </div>
</template>

<script setup lang="ts">
import Button from '@/components/common/buttons/Button.vue'
import {
  computed,
  defineProps,
  ref,
  watchEffect,
  defineEmits,
  onMounted,
} from 'vue'
import {
  GitHosting,
  GitProject,
  GlobalExclusionRules,
} from '@/store/modules/admin/git'
import { showToastError } from '@/utils/utils'
import { useStore } from '@/store'
import { useToast } from 'primevue/usetoast'
import { clone, pick } from 'ramda'
import useVuelidate from '@vuelidate/core'
import BaseTextarea from '@/components/common/base/BaseTextarea.vue'
import Link from '@/components/common/Link.vue'

const props = defineProps<{
  git_hosting: GitHosting
  project: GitProject | null
}>()
const emit = defineEmits<{
  (e: 'close-popup'): void
  (e: 'update-table'): void
}>()
const store = useStore()
const toast = useToast()
const initialFormData = () => ({
  commit_message_exclusion_rule: null,
  file_path_exclusion_rule: null,
})
const formData = ref<GlobalExclusionRules>(initialFormData())
const formRules = {
  commit_message_exclusion_rule: {
    onlyBackend: () => true,
    $autoDirty: true,
  },
  file_path_exclusion_rule: {
    onlyBackend: () => true,
    $autoDirty: true,
  },
}
const $externalResults = ref({})
const v$ = useVuelidate(formRules, formData, { $externalResults })

const title = computed(
  () => `${props.project ? props.project.name : 'Global'} Exclusion Rules`
)
const globalRules = computed(() => store.state.git.global_exclusion_rules)

onMounted(() => {
  if (!props.project) {
    store
      .dispatch('git/getGlobalExclusionRules')
      .catch((e) => showToastError(toast, e))
  }
})

watchEffect(() => {
  formData.value = props.project
    ? pick(
        ['commit_message_exclusion_rule', 'file_path_exclusion_rule'],
        props.project
      )
    : clone(globalRules.value)
})

const isEveryObjPropertyNull = (obj: GlobalExclusionRules) => {
  return Object.values(obj).every((o) => o === null)
}

const onApplyClick = async () => {
  if (props.project) {
    applyProjectRules()
  } else {
    if (
      isEveryObjPropertyNull(globalRules.value) &&
      !isEveryObjPropertyNull(formData.value)
    ) {
      addGlobalRules()
    } else if (
      !isEveryObjPropertyNull(globalRules.value) &&
      isEveryObjPropertyNull(formData.value)
    ) {
      deleteGlobalRules()
    } else if (!isEveryObjPropertyNull(formData.value)) {
      editGlobalRules()
    }
  }
}

async function addGlobalRules() {
  try {
    await store.dispatch('git/addGlobalExclusionRules', {
      ...formData.value,
    })
    emit('close-popup')
    emit('update-table')
  } catch (e: any) {
    if (
      e.response?.data?.commit_message_exclusion_rule ||
      e.response?.data?.file_path_exclusion_rule
    ) {
      $externalResults.value = e.response.data
    } else {
      showToastError(toast, e)
    }
  }
}

async function deleteGlobalRules() {
  try {
    await store.dispatch('git/deleteGlobalExclusionRules')
    emit('update-table')
    emit('close-popup')
  } catch (e) {
    showToastError(toast, e)
  }
}

async function editGlobalRules() {
  try {
    await store.dispatch('git/editGlobalExclusionRules', {
      ...formData.value,
    })
    emit('update-table')
    emit('close-popup')
  } catch (e: any) {
    if (
      e.response?.data?.commit_message_exclusion_rule ||
      e.response?.data?.file_path_exclusion_rule
    ) {
      $externalResults.value = e.response.data
    } else {
      showToastError(toast, e)
    }
  }
}

async function applyProjectRules() {
  try {
    await store.dispatch(`${props.git_hosting.toLowerCase()}/editProject`, {
      ...formData.value,
      id: props.project?.id,
    })
    emit('update-table')
    emit('close-popup')
  } catch (e) {
    showToastError(toast, e)
  }
}
</script>

<style scoped>
.header-wrapper {
  @apply p-3 bg-gray-50 flex justify-end;
}

.content-wrapper {
  @apply px-5 py-3;
}

.title {
  @apply text-gray-600 font-semi-bold mb-2;
}
</style>
