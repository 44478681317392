<template>
  <Editor
    :model-value="value"
    editorStyle="height: 20vh; maxHeight: 300px"
    @update:modelValue="emit('update:value', $event)"
    :modules="modules"
  >
    <template v-slot:toolbar>
      <span class="ql-formats">
        <button class="ql-bold"></button>
        <button class="ql-italic"></button>
        <button class="ql-underline"></button>
        <button class="ql-strike"></button>
        <button class="ql-list" value="ordered"></button>
        <button class="ql-list" value="bullet"></button>
        <button class="ql-link"></button>
      </span>
    </template>
  </Editor>
</template>

<script setup lang="ts">
import Editor from 'primevue/editor'
import { defineProps, defineEmits } from 'vue'
import Delta from 'quill-delta'

defineProps<{ value: string | null }>()
const emit = defineEmits<{ (e: 'update:modelValue', value: string): void }>()

function customMatcher(node, delta) {
  return delta.compose(
    new Delta().retain(delta.length(), { color: false, background: false })
  )
}

const modules = {
  clipboard: {
    matchers: [[Node.ELEMENT_NODE, customMatcher]],
  },
}
</script>
