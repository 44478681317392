import axios from '@/services/axios'
import { omit, path } from 'ramda'
import { GIT_HOSTING, LIMIT_OF_COMMITS } from '@/constants/constants'
import { ActionContext } from 'vuex'
import { State as GlobalState } from '../../index'
import { GitProject, CommitsFilter } from '@/store/modules/admin/git'

export interface GithubUser {
  company?: number
  email?: string | null
  id: number
  name?: string | null
  original_name?: number | null
  login: string
}

export interface State {
  projects: GitProject[]
  commits_filters: CommitsFilter
  search: string | null
  isGithubConnected: boolean
}

export type GithubRedirectPage =
  | 'ADMINISTRATION'
  | 'COMPANY_CREATION'
  | 'PROJECT_MAPPING_CREATION'

export interface GithubConnection {
  connected: boolean
  install_requested_from: GithubRedirectPage
  install_requested_from_project_mapping: number
}

type Context = ActionContext<State, GlobalState>

const state = (): State => ({
  projects: [],
  commits_filters: {
    projects: null,
    users: null,
    since: null,
    until: null,
    insertions_gte: null,
    insertions_lte: null,
    deletions_gte: null,
    deletions_lte: null,
    duplicated_lines_gte: null,
    duplicated_lines_lte: null,
  },
  search: null,
  isGithubConnected: false,
})

const mutations = {
  setProjects(state: State, projects: GitProject[]): void {
    state.projects = projects
  },
  setGithubConnection(state: State, connected: boolean): void {
    state.isGithubConnected = connected
  },
  setCommitsFilters(state: State, filters: CommitsFilter): void {
    state.commits_filters = filters
  },
  setSearchText(state: State, search: string | null): void {
    state.search = search
  },
}

const actions = {
  getInstallationUrl(
    { rootGetters }: Context,
    params: {
      install_requested_from: GithubRedirectPage
      install_requested_from_project_mapping?: number
    }
  ): Promise<void> {
    return axios
      .post(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/github/app/install-url/`,
        { ...params }
      )
      .then(path(['data']))
  },
  setGithubSetup(
    { rootGetters }: Context,
    params: {
      installation_id: number
      setup_action: string
    }
  ): Promise<void> {
    return axios
      .post(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/github/app/setup/`,
        { ...params }
      )
      .then(path(['data']))
  },
  checkGithubConnection({
    commit,
    rootGetters,
  }: Context): Promise<GithubConnection> {
    return axios
      .get(
        `api/companies/${rootGetters['company/selectedCompanyId']}/github/app/check-connection/`
      )
      .then((data) => {
        commit('setGithubConnection', data.data.connected)
        return data.data
      })
  },
  resetGithubConnection({ commit }: Context): void {
    commit('setGithubConnection', null)
  },
  getProjects({ commit, rootGetters }: Context): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/repositories/?git_hosting=${GIT_HOSTING.Github}`
      )
      .then((data) => commit('setProjects', data.data))
  },
  getProject({ rootGetters }: Context, id: number): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/repositories/${id}/?git_hosting=${GIT_HOSTING.Github}`
      )
      .then(path(['data']))
  },
  editProject(
    { dispatch, rootGetters }: Context,
    project: GitProject
  ): Promise<void> {
    return axios
      .patch(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/repositories/${project.id}/?git_hosting=${GIT_HOSTING.Github}`,
        omit(['id'], project)
      )
      .then(path(['data']))
      .then(() => dispatch('getProjects'))
  },
  deleteProject({ dispatch, rootGetters }: Context, id: number): Promise<void> {
    return axios
      .delete(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/repositories/${id}/?git_hosting=${GIT_HOSTING.Github}`
      )
      .then(path(['data']))
      .then(() => dispatch('getProjects'))
  },
  addProjects(
    { dispatch, rootGetters }: Context,
    payload: { id: number; name: string; original_project: number }
  ): Promise<GitProject[]> {
    return axios
      .post(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/github/add_repositories/`,
        payload
      )
      .then((data) => {
        dispatch('getProjects')
        return data.data
      })
  },
  getNotAddedProjects({ rootGetters }: Context): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/github/not_added_repositories/`
      )
      .then(path(['data']))
  },
  getFirstPageOfUsers(
    { rootGetters }: Context,
    params: { ordering: string | null; search: string }
  ): Promise<void> {
    return axios
      .get(
        `/api/companies/${
          rootGetters['company/selectedCompanyId']
        }/github/users/?limit=50&ordering=${params.ordering}&unmapped=false${
          params.search ? `&search=${params.search}` : ''
        }`
      )
      .then(path(['data']))
  },
  getNextPageOfUsers(_: Context, url: string): Promise<void> {
    return axios.get(`${url}`).then(path(['data']))
  },
  editUser(
    { rootGetters }: Context,
    user: { original_name: number; id: number }
  ): Promise<void> {
    return axios
      .patch(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/github/users/${user.id}/`,
        omit(['id'], user)
      )
      .then(path(['data']))
  },
  getFirstPageOfCommits(
    { rootGetters }: Context,
    params: { params: CommitsFilter; signal: AbortSignal }
  ): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/commits/?limit=${LIMIT_OF_COMMITS}&git_hosting=${GIT_HOSTING.Github}`,
        params
      )
      .then(path(['data']))
  },
  getNextPageOfCommits(_: Context, url: string): Promise<void> {
    return axios.get(`${url}`).then(path(['data']))
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
