<template>
  <div class="height flex" v-if="loading">
    <LoaderWrapper></LoaderWrapper>
  </div>
  <div v-else-if="githubConnected">
    <GithubProjectsTable></GithubProjectsTable>
    <Divider class="my-8"></Divider>
    <GithubUsersTable></GithubUsersTable>
    <Divider class="my-8"></Divider>
    <CommitsTable></CommitsTable>
    <Divider class="mt-8"></Divider>
  </div>
  <div v-else class="flow-wrapper">
    <GithubConnectionFlow class="w-1/2" with-title></GithubConnectionFlow>
  </div>
</template>

<script setup lang="ts">
import GithubProjectsTable from '@/components/admin-dashboard/github/projects/GithubProjectsTable.vue'
import CommitsTable from '@/components/admin-dashboard/github/commits/CommitsTable.vue'
import { computed, onMounted, ref } from 'vue'
import { showToastError } from '@/utils/utils'
import { useStore } from '@/store'
import { useToast } from 'primevue/usetoast'
import LoaderWrapper from '@/components/common/loader/LoaderWrapper.vue'
import GithubUsersTable from '@/components/admin-dashboard/github/users/GithubUsersTable.vue'
import GithubConnectionFlow from '@/components/admin-dashboard/github/GithubConnectionFlow.vue'

const store = useStore()
const toast = useToast()
const githubConnected = computed(() => store.state.github.isGithubConnected)
const loading = ref(false)

onMounted(() => {
  loading.value = true
  store
    .dispatch('github/checkGithubConnection')
    .catch((e) => {
      showToastError(toast, e)
    })
    .finally(() => (loading.value = false))
})
</script>

<style scoped>
.flow-wrapper {
  @apply bg-gray-50 p-8;
}
.height {
  height: 450px;
}
</style>
