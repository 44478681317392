<template>
  <div class="w-full h-full flex justify-center items-center">
    <Loader></Loader>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { showToastError } from '@/utils/utils'
import { useStore } from '@/store'
import { useToast } from 'primevue/usetoast'
import Loader from '@/components/common/loader/Loader.vue'
import { GithubConnection } from '@/store/modules/admin/github'
import { useRouter, useRoute } from 'vue-router'

const store = useStore()
const toast = useToast()
const connection = ref<GithubConnection | null>(null)
const router = useRouter()
const route = useRoute()

const REDIRECT_PAGES = {
  ADMINISTRATION: 'ADMINISTRATION',
  PROJECT_MAPPING_CREATION: 'PROJECT_MAPPING_CREATION',
  COMPANY_CREATION: 'COMPANY_CREATION',
}

onMounted(async () => {
  const company = JSON.parse(
    window.localStorage.getItem('selectedCompany') || ''
  )
  await store.dispatch('company/saveSelectedCompanyInLocalStorage', company)

  await store.dispatch('github/setGithubSetup', {
    installation_id: +route.query.installation_id,
    setup_action: route.query.setup_action,
  })

  connection.value = await store
    .dispatch('github/checkGithubConnection')
    .catch((e) => {
      showToastError(toast, e)
    })
  if (
    connection.value?.install_requested_from === REDIRECT_PAGES.COMPANY_CREATION
  ) {
    await router.push('/company-creation?githubIntegration=true')
  } else if (
    connection.value?.install_requested_from ===
      REDIRECT_PAGES.ADMINISTRATION &&
    !connection.value.install_requested_from_project_mapping
  ) {
    await router.push(
      `/company/${store.state.company.selectedCompanyId}/administration?tab=2`
    )
  } else if (
    connection.value?.install_requested_from ===
      REDIRECT_PAGES.ADMINISTRATION &&
    connection.value.install_requested_from_project_mapping
  ) {
    await router.push(
      `/company/${store.state.company.selectedCompanyId}/administration?tab=0&githubIntegration=true&projectId=${connection.value.install_requested_from_project_mapping}`
    )
  }
})
</script>
